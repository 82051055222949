import React from 'react';
import classes from './QuoteItem.module.scss';

interface Props {
  title: string;
  description: string;
  options?: {option: string, variant: string}[];
  quantity: number;
}

const QuoteItem: React.FC<Props> = ({
  title = '',
  description = '',
  options,
  quantity,
}) => (
  <div className={classes.quoteItem}>
    <div className={classes.quoteItem__col}>
      <h3 className={classes.quoteItem__title}>
        {title}
      </h3>
      <p className={classes.quoteItem__descr}>
        {description}
      </p>
      <p className={classes.quoteItem__options}>
        {options?.map((option) => (
          <React.Fragment key={option.option}>
            {option.variant}{' '}
          </React.Fragment>
        ))}
      </p>
    </div>
    <div className={classes.quoteItem__col}>
      <p className={classes.quoteItem__quantity}>
        QTY: {quantity}
      </p>
    </div>
  </div>
);

export default QuoteItem;
