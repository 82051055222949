import React from 'react';
import classes from './QuotePricingItem.module.scss';
// Components
import ItemModal from './ItemModal';
// Types
import { ItemType } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

const QuotePricingItem: React.FC<ItemType> = ({
  id,
  name = '',
  description = '',
  quantity,
  unitPrice,
  options = [],
  currencyIso,
}) => (
  <div className={classes.quote__item}>
    <div className={classes.quote__item_info}>
      <div className={classes.quote__item_info_wrapper}>
        <div className={classes.quote__item_indent}>
          {name[0].toUpperCase()}
        </div>
        <div className={classes.quote__item_infotext}>
          <h3 className={classes.quote__item_title}>
            {name}
          </h3>
          <p className={classes.quote__item_descr}>
            {description}
          </p>
        </div>
      </div>
      <p className={classes.quote__item_options}>
        {options.map((option) => (
          <React.Fragment key={option.option}>
            {option.variant}{' '}
          </React.Fragment>
        ))}
      </p>
    </div>
    <div className={classes.quote__item_col}>
      <p className={classes.quote__item_col_title}>
        Qty
      </p>
      <span className={classes.quote__item_quantity}>
        {quantity}
      </span>
    </div>
    <div className={`${classes.quote__item_col} ${classes.quote__item_col_price}`}>
      <div className={classes.quote__item_col_text}>
        <p className={classes.quote__item_col_title}>
          Price
        </p>
        {unitPrice && (
          <>
            <span className={classes.quote__item_total}>
              {CurrencyFormat(quantity * unitPrice, currencyIso)}
            </span>
            <span className={classes.quote__item_price}>
              {CurrencyFormat(unitPrice, currencyIso)} each
            </span>
          </>
        )}
      </div>
      <ItemModal
        itemId={id}
        itemName={name}
        itemOptions={options}
        itemsQuantity={quantity}
        itemPrice={unitPrice}
      />
    </div>
  </div>
);

export default QuotePricingItem;
