import React from 'react';
// Components
import SectionHeading from 'components/Typography/SectionHeading';
// Styles
import classes from './InvoicePrice.module.scss';
// Types
import { PriceType } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

interface Props {
  price: PriceType
}

const InvoicePrice: React.FC<Props> = ({ price }) => (
  <section className={classes.container}>
    <div className={classes.taxes}>
      <SectionHeading
        text="taxes"
        isUppercase
      />
      <div className={classes.row}>
        <p className={classes.row__title}>
          VAT @ {price.vat}%
        </p>
        <span className={classes.row__price}>
          {CurrencyFormat((price.subtotal / 100) * price.vat, price.currencyIso)}
        </span>
      </div>
      <div className={classes.row}>
        <p className={classes.row__title}>
          Import tax @ {price.importTax}%
        </p>
        <span className={classes.row__price}>
          {CurrencyFormat((price.subtotal / 100) * price.importTax, price.currencyIso)}
        </span>
      </div>
    </div>
    <div className={classes.subtotal}>
      <div className={classes.subtotal_row}>
        <p className={classes.subtotal_row__title}>
          Subtotal
        </p>
        <span className={classes.subtotal_row__price}>
          {CurrencyFormat(price.subtotal, price.currencyIso)}
        </span>
      </div>
      <div className={classes.subtotal_row}>
        <p className={classes.subtotal_row__title}>
          Discount
        </p>
        <span className={classes.subtotal_row__price}>
          {CurrencyFormat(price.discount || 0, price.currencyIso)}
        </span>
      </div>
    </div>
    <div className={classes.total}>
      <p className={classes.total__title}>
        total
      </p>
      <span className={classes.total__price}>
        {CurrencyFormat(price.total, price.currencyIso)}
      </span>
    </div>
  </section>
);

export default InvoicePrice;
