import React, { useState } from 'react';
// Styles
import classes from './PaymentMethods.module.scss';
// Components
import { Button } from 'react-bootstrap';
import CardForm from 'components/Stripe/CardForm';
import PaymentMethod from 'components/PaymentMethod';
// Icons
import { ReactComponent as VisaIcon } from 'assets/payment-icons/visa.svg';
import { ReactComponent as MastercardIcon } from 'assets/payment-icons/mastercard.svg';
import { ReactComponent as SepaIcon } from 'assets/payment-icons/sepa.svg';
import { ReactComponent as GiropayIcon } from 'assets/payment-icons/giropay.svg';
import { ReactComponent as SofortIcon } from 'assets/payment-icons/sofort.svg';
import { ReactComponent as IdealIcon } from 'assets/payment-icons/ideal.svg';
import { ReactComponent as BancontactIcon } from 'assets/payment-icons/bancontact.svg';
import { ReactComponent as DownIcon } from 'assets/icons/down-chevron.svg';
import DueButton from 'components/Buttons/DueButton';
// Types
import { PriceType, TermsPaymentType } from 'types/globalTypes';
// Utils
import { TotalPrice } from 'utils/functions';

interface Props {
  documentId: string
  price: PriceType
  termsPayment: TermsPaymentType
  instalmentIndex: string
  contactFullName: string
}

const PaymentMethods: React.FC<Props> = ({
  documentId,
  price,
  termsPayment,
  instalmentIndex,
  contactFullName,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>('');

  const toggleAccordion = () => {
    setOpen(!open);
  };

  const handlerPaymentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(event.target.value);
  };

  return (
    <>
      <div className={classes.container}>
        <PaymentMethod
          title="Card"
          onChange={handlerPaymentType}
          paymentComponent={
            (
              <CardForm
                documentId={documentId}
                price={termsPayment.instalements
                  ? TotalPrice(termsPayment.paymentSchedule[Number(instalmentIndex)].amount)
                  : TotalPrice(price.total, price.vatInclusive, price.vat)}
                currencyIso={price.currencyIso}
                contactFullName={contactFullName}
              />
            )
          }
          inputProps={{
            id: 'stripe',
            name: 'group',
            value: 'stripe',
          }}
        >
          <VisaIcon style={{ width: '39px', height: '39px' }} />
          <MastercardIcon style={{ width: '39px', height: '39px' }} />
        </PaymentMethod>
        <div className={classes.method__card}>
          <input
            onChange={handlerPaymentType}
            id="sepa"
            name="group"
            type="radio"
            value="sepa"
          />
          <label className={classes.method__card_label} htmlFor="sepa">
            <div className={classes.method__card_icon}>
              <SepaIcon />
            </div>
            <span className={classes.method__card_title}>SEPA Direct Debit</span>
          </label>
        </div>
        <div className={classes.method__card}>
          <input
            onChange={handlerPaymentType}
            id="giropay"
            name="group"
            type="radio"
            value="giropay"
          />
          <label className={classes.method__card_label} htmlFor="giropay">
            <div className={classes.method__card_icon}>
              <GiropayIcon />
            </div>
            <span className={classes.method__card_title}>GiroPay</span>
          </label>
        </div>
        <div className={open
          ? `${classes.accordion_container} ${classes.accordion_container__open}`
          : classes.accordion_container}
        >
          <div className={classes.method__card}>
            <input
              onChange={handlerPaymentType}
              id="sofort"
              name="group"
              type="radio"
              value="sofort"
            />
            <label className={classes.method__card_label} htmlFor="sofort">
              <div className={classes.method__card_icon}>
                <SofortIcon style={{ width: '60px', height: '30px' }} />
              </div>
              <span className={classes.method__card_title}>SOFORT</span>
            </label>
          </div>
          <div className={classes.method__card}>
            <input
              onChange={handlerPaymentType}
              id="ideal"
              name="group"
              type="radio"
              value="ideal"
            />
            <label className={classes.method__card_label} htmlFor="ideal">
              <div className={classes.method__card_icon}>
                <IdealIcon />
              </div>
              <span className={classes.method__card_title}>IDEAL</span>
            </label>
          </div>
          <div className={classes.method__card}>
            <input
              onChange={handlerPaymentType}
              id="bancontact"
              name="group"
              type="radio"
              value="bancontact"
            />
            <label className={classes.method__card_label} htmlFor="bancontact">
              <div className={classes.method__card_icon}>
                <BancontactIcon />
              </div>
              <span className={classes.method__card_title}>Bancontact</span>
            </label>
          </div>
        </div>
      </div>
      <Button
        type="button"
        onClick={toggleAccordion}
        bsPrefix={classes.accordion_btn}
      >
        <span>
          {open ? 'Less payment options' : 'More payment options'}
        </span>
        <DownIcon className={open ? classes.accordion_btn_icon : classes.accordion_btn_icon_more} />
      </Button>
      {paymentType === '' && (
        <DueButton
          disabled
          type="button"
          price={termsPayment.instalements
            ? TotalPrice(termsPayment.paymentSchedule[Number(instalmentIndex)].amount)
            : TotalPrice(price.total, price.vatInclusive, price.vat)}
          currencyIso={price.currencyIso}
        />
      )}
    </>
  );
};

export default PaymentMethods;
