import React from 'react';
import dayjs from 'dayjs';
// Styles
import classes from './BillPayment.module.scss';
// Components
import { Button } from 'react-bootstrap';
import PaymentSchedule from './PaymentSchedule';
import SectionHeading from 'components/Typography/SectionHeading';
// Types
import { TermsPaymentType } from 'types/globalTypes';
// Utils
import { RemainingBalance, CurrencyFormat } from 'utils/functions';

interface Props {
  termsPayment: TermsPaymentType
  isPaid: boolean
  currencyIso: string
}

const BillPayment: React.FC<Props> = ({
  termsPayment,
  isPaid,
  currencyIso,
}) => (
  <section className={classes.container}>
    <SectionHeading
      text="Terms & payment"
      isUppercase
    />
    <div className={classes.payment__row}>
      <p className={classes.payment__row_title}>
        Payment due
      </p>
      <span className={classes.payment__row_subtitle}>
        {termsPayment.dueDate}
      </span>
    </div>
    <div className={classes.payment__row}>
      <p className={classes.payment__row_title}>
        Balance payable in instalments?
      </p>
      <span className={classes.payment__row_subtitle}>
        {termsPayment.instalements ? 'Yes' : 'No'}
      </span>
    </div>
    {termsPayment.instalements && (
      <PaymentSchedule
        schedule={termsPayment.paymentSchedule}
      />
    )}
    <div className={classes.notes}>
      <SectionHeading
        text="bill notes"
        isUppercase
      />
      <p className={classes.notes__text}>
        {termsPayment.notes}
      </p>
    </div>
    <div className={classes.status}>
      <div className={classes.status__row}>
        <SectionHeading
          text="bill status"
          isUppercase
        />
        {isPaid ? (
          <span className={classes.status__paid}>
            paid
          </span>
        ) : (
          <span className={`${classes.status__paid} ${classes.status__unpaid}`}>
            unpaid
          </span>
        )}
      </div>
      {isPaid && termsPayment.payments.map((item) => (
        <React.Fragment key={item.id}>
          <div className={classes.status__row}>
            <p className={classes.status__row_title}>
              Paid on
            </p>
            <span className={classes.status__row_subtitle}>
              {dayjs(item.paymentDate).format('DD MMM YYYY')}
            </span>
          </div>
          <div className={classes.status__row}>
            <p className={classes.status__row_title}>
              Paid with
            </p>
            <span className={classes.status__row_subtitle}>
              {item.paymentMethod.alias}
            </span>
          </div>
        </React.Fragment>
      ))}
    </div>
    <div className={classes.balance}>
      <p className={classes.balance__title}>
        Remaining Balance
      </p>
      <span className={classes.balance__price}>
        {CurrencyFormat(
          RemainingBalance(termsPayment.remainingBalance, termsPayment.paymentSchedule),
          currencyIso,
        )}
      </span>
    </div>
    {(termsPayment.paymentSchedule?.length === 0 || termsPayment.paymentSchedule === null) && (
      <div className={classes.button__block}>
        <Button variant="secondary" className={classes.pay_now_btn}>
          Pay now
        </Button>
      </div>
    )}
  </section>
);

export default BillPayment;
