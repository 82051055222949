import React, { useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { Modal, Button } from 'react-bootstrap';
// Styles
import classes from './ItemModal.module.scss';
// Images
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
// Actions
import { QuoteUpdateItemsAction } from 'store/quote/quoteActions';
import { ItemType } from 'types/globalTypes';

interface Props {
  itemId: number | undefined
  itemName: string
  itemOptions: {option: string, variant: string}[]
  itemsQuantity: number
  itemPrice?: number | undefined
}

const ItemModal: React.FC<Props> = ({
  itemId,
  itemName = '',
  itemOptions = [],
  itemsQuantity = '',
  itemPrice,
}) => {
  const dispatch = useDispatch();
  const { items } = useTypeSelector((state) => state.quote);
  const [show, setShow] = useState<boolean>(false);
  const [price, setPrice] = useState<string>('');

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    document.body.style.paddingRight = '0';
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const handleAddPrice = () => {
    const newItemsArray: ItemType[] = [...items];
    const itemIndex: number = newItemsArray.findIndex((item) => item.id === itemId);
    // eslint-disable-next-line
    newItemsArray[itemIndex].unitPrice = Number(parseFloat(price).toFixed(2)) / Number(itemsQuantity);
    dispatch(QuoteUpdateItemsAction(newItemsArray));
    setShow(false);
  };

  useLayoutEffect(() => {
    if (itemPrice) {
      setPrice(String(itemPrice * Number(itemsQuantity)));
    }
  }, [itemPrice]);

  return (
    <>
      <Button bsPrefix={classes.modal__btn} onClick={handleShow}>
        {itemPrice ? 'Edit' : 'Add price'}
      </Button>

      <Modal show={show} onHide={handleClose} centered dialogClassName={classes.modal}>
        <div className={classes.modal__content}>
          <Button bsPrefix={classes.modal__close_btn} type="button" onClick={handleClose}>
            <CloseIcon />
          </Button>
          <h3 className={classes.modal__title}>
            {itemName}
          </h3>
          <p className={classes.modal__options}>
            {itemOptions.map((option) => (
              <React.Fragment key={option.option}>
                {option.variant}{' '}
              </React.Fragment>
            ))}
          </p>
          <p className={classes.modal__quantity}>
            Quantity: <span>{itemsQuantity}</span>
          </p>
          <div className={classes.modal__input_container}>
            <span className={classes.modal__input_icon}>€</span>
            <input
              inputMode="decimal"
              step=".01"
              type="number"
              placeholder="Enter price"
              value={price}
              onChange={handleChange}
            />
          </div>
          <Button onClick={handleAddPrice} type="button" bsPrefix={classes.modal__add_btn}>
            Add
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ItemModal;
