import {
  OrganisationType,
  ContactType,
  ItemType,
  PriceType,
} from 'types/globalTypes';

// Action Types
export const ESTIMATE_LOADING = 'ESTIMATE_LOADING';
export const ESTIMATE_GET_DATA = 'ESTIMATE_GET_DATA';

// Reducer type
export interface EstimateState {
  loading: boolean
  organisation: OrganisationType
  contact: ContactType
  details: EstimateDetailsType
  items: ItemType[]
  price: PriceType
  termsPayment: EstimateTermsPaymentType
  status: string
}

export type EstimateTermsPaymentType = {
  dueDate: string
  notes: string
  expiryDate: string
}

export type EstimateDetailsType = {
  type: string,
  estimateNumber: string,
  estimateDate: string,
}

interface EstimateLoadingAction {
  type: typeof ESTIMATE_LOADING
  payload: boolean
}

interface GetEstimateDataAction {
  type: typeof ESTIMATE_GET_DATA
  payload: EstimateState
}

export type EstimateActions = EstimateLoadingAction | GetEstimateDataAction;
