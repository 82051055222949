import { AppThunk } from 'store';
import axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from 'utils/constants';
import {
  QUOTE_LOADING,
  QuoteActions,
  QuoteState,
  QUOTE_GET_DATA,
  QUOTE_UPDATE_ITEMS,
  QUOTE_SEND_ITEMS,
} from './quoteTypes';
import { ItemType } from 'types/globalTypes';

const QuoteLoadingAction = (payload: boolean): QuoteActions => ({
  type: QUOTE_LOADING,
  payload,
});

const QuoteGetDataAction = (payload: QuoteState): QuoteActions => ({
  type: QUOTE_GET_DATA,
  payload,
});

export const QuoteUpdateItemsAction = (payload: ItemType[]): QuoteActions => ({
  type: QUOTE_UPDATE_ITEMS,
  payload,
});

export const QuoteSendItemsAction = (payload: boolean): QuoteActions => ({
  type: QUOTE_SEND_ITEMS,
  payload,
});

export const GetQuoteData = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(QuoteLoadingAction(true));

    const { data } = await axios.get(`${API_URL}/shared-purchase-orders/${id}`);

    const quantity = data.items.reduce((totalQuantity: number, item: ItemType) => (
      totalQuantity + item.quantity
    ), 0);

    if (data) {
      const quoteData = {
        loading: false,
        organisation: {
          logo: data.organisation.logo || null,
          name: data.organisation.name,
          phone: data.organisation.phoneNumber,
          email: data.organisation.email,
          address: `${data.organisation.address.address1 || ''} ${data.organisation.address.city || ''} ${data.organisation.address.postalCode || ''}`,
          country: data.organisation.country.name,
        },
        contact: {
          name: data.contact.firstName,
          fullName: `${data.contact.firstName} ${data.contact.lastName}`,
          phone: data.contact.mobileNumber,
          address: `${data.contact.address.address1} ${data.contact.address.city} ${data.contact.address.postalCode}`,
        },
        details: {
          type: data.type,
          quoteNumber: data.reference,
          quoteDate: dayjs(data.createdAt).format('DD MMM YYYY'),
          currencyIso: data.book.country.currencyISO,
        },
        items: data.items,
        itemsQuantity: quantity,
        includeTax: false,
        termsPayment: {
          notes: data.notes,
          expiryDate: dayjs(data.expiryDate).format('DD MMM YYYY'),
        },
        status: data.status.name,
      };
      dispatch(QuoteGetDataAction(quoteData));
    }
  } catch (error) {
    console.log(error);
  }
};

export const SendItemsData = (id: string, items: ItemType[]):AppThunk => async (dispatch) => {
  try {
    dispatch(QuoteLoadingAction(true));
    const { status } = await axios.patch(`${API_URL}/shared-purchase-orders/${id}`,
      JSON.stringify(items),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      });

    if (status === 200) {
      dispatch(QuoteSendItemsAction(true));
    }

    dispatch(QuoteLoadingAction(false));
  } catch (error) {
    console.log(error);
  }
};
