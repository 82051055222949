import { AppThunk } from 'store';
import axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from 'utils/constants';
import { ItemType } from 'types/globalTypes';
import {
  INVOICE_LOADING,
  InvoiceActions,
  InvoiceState,
  INVOICE_GET_DATA,
  INVOICE_PAY_SUCCESS,
} from './invoiceTypes';

const InvoiceLoadingAction = (payload: boolean): InvoiceActions => ({
  type: INVOICE_LOADING,
  payload,
});

const InvoiceGetDataAction = (payload: InvoiceState): InvoiceActions => ({
  type: INVOICE_GET_DATA,
  payload,
});

export const InvoiceSetPaySuccessAction = (payload: any): InvoiceActions => ({
  type: INVOICE_PAY_SUCCESS,
  payload,
});

export const GetInvoiceData = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(InvoiceLoadingAction(true));

    const { data } = await axios.get(`${API_URL}/shared-invoices/${id}`);
    if (data) {
      const subtotal = data.items.reduce((acc: number, item: ItemType) => (
        acc + (item.unitPrice * item.quantity)
      ), 0);

      console.log(data);

      const invoiceData = {
        loading: false,
        organisation: {
          logo: data.organisation.logo || null,
          name: data.organisation.name,
          phone: `${data.organisation.country.mobileIDD} ${data.organisation.phoneNumber}`,
          email: data.organisation.email,
          address: `${data.organisation.address.address1 || ''} ${data.organisation.address.city || ''} ${data.organisation.address.postalCode || ''}`,
          country: data.organisation.country.name,
        },
        contact: {
          name: data.contact.firstName,
          fullName: `${data.contact.firstName} ${data.contact.lastName}`,
          phone: `${data.book.country.mobileIDD} ${data.contact.mobileNumber.replace(/(\d)(?=(\d{2})+(\D|$))/g, '$1 ')}`,
          address: `${data.contact.address.address1} ${data.contact.address.city} ${data.contact.address.postalCode}`,
        },
        details: {
          type: data.type,
          invoiceNumber: data.reference,
          invoiceDate: dayjs(data.createdAt).format('DD MMM YYYY'),
        },
        items: data.items,
        price: {
          currencyIso: data.book.country.currencyISO,
          vatInclusive: data.book.taxDetails[0].type !== 'EXCLUSIVE',
          vat: data.book.taxDetails[0].rate,
          importTax: data.book.taxDetails[1].rate,
          subtotal,
          discount: data.discountAmount,
          total: subtotal - data.discountAmount,
        },
        termsPayment: {
          dueDate: dayjs(data.dueDate).format('DD MMM YYYY'),
          instalements: data.status.name !== 'PAID' && data.terms !== null,
          notes: data.notes,
          payments: data.payments,
          remainingBalance: data.status.name !== 'PAID' ? subtotal - data.discountAmount : 0,
          paymentSchedule: data.status.name !== 'PAID' && data.terms !== null ? data.terms.paymentSchedule : [],
        },
        paid: data.status.name === 'PAID',
      };
      dispatch(InvoiceGetDataAction(invoiceData));
    }
  } catch (error) {
    console.log(error);
  }
};
