import React from 'react';
import { Link } from 'react-router-dom';
// Styles
import classes from './QuotePricingHeader.module.scss';
// Components
import YellowRoundBtn from 'components/Buttons/YellowRoundBtn';
// Types
import { OrganisationType } from 'types/globalTypes';

interface Props {
  organisation: OrganisationType
  scrollTo: () => void
  sent?: boolean
}

const QuotePricingHeader: React.FC<Props> = ({ organisation, scrollTo, sent }) => (
  <header className={sent ? classes.quote__header_sent : classes.quote__header}>
    <div className={classes.quote__header_img} />
    <div className={classes.quote__header_wrapper}>
      <h1 className={classes.quote__header_title}>
        {sent ? 'Quote sent!' : 'Your Quote request from'}
      </h1>
      {!sent && (
        <a className={classes.quote__header_company} href="#">
          <div className={classes.quote__header_company_logo}>
            {organisation.logo === '' || organisation.logo === undefined ? (
              <span>
                {organisation.name[0].toUpperCase()}
              </span>
            ) : (
              <img src={organisation.logo} alt="logo" />
            )}
          </div>
          <h3 className={classes.quote__header_company_name}>
            <i>{organisation.name}</i>
          </h3>
        </a>
      )}
      <p className={sent ? classes.quote__header_descr_sent : classes.quote__header_descr}>
        {sent
          ? 'You will get a response from the requester if your quote is accepted or rejected.'
          : 'Add the pricing details for the quote and click “Send” when you’re done. You will get a response from the requester if your quote is accepted or rejected.'}
      </p>
      <p className={classes.quote__header_learnmore}>
        <Link to="#" className={classes.quote__header_learnmore_link}>
          Learn more
        </Link>
        about how you can use Leja to create,
        send and track estimates and quotes for your own business today.
      </p>
      <YellowRoundBtn
        onClick={sent ? undefined : scrollTo}
        type="button"
      >
        {sent ? 'Explore Leja' : 'Add Details'}
      </YellowRoundBtn>
    </div>
  </header>
);

export default QuotePricingHeader;
