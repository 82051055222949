/**
 * Payment method component
 * Used in pages/checkout/PaymentMethods
 * Pass children for icons
*/
import React from 'react';
// Styles
import classes from './PaymentMethod.module.scss';

interface Props {
  title: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  paymentComponent: React.ReactNode
  inputProps: {
    id: string
    name: string
    value: string
  }
}

const PaymentMethod: React.FC<Props> = ({
  title,
  onChange,
  children,
  paymentComponent,
  inputProps,
}) => (
  <>
    <div className={classes.method__card}>
      <input
        onChange={onChange}
        type="radio"
        {...inputProps}
      />
      <label className={classes.method__card_label} htmlFor={inputProps.id}>
        <div className={classes.method__card_icon}>
          {children}
        </div>
        <span className={classes.method__card_title}>
          {title}
        </span>
      </label>
      <div className={classes.accordion_card}>
        {paymentComponent}
      </div>
    </div>
  </>
);

export default PaymentMethod;
