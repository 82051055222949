import { ScheduleItem } from 'types/globalTypes';

export const RemainingBalance = (balance: number, payments: ScheduleItem[] | undefined): number => {
  if (!payments) {
    return balance;
  }

  return balance - payments.reduce((acc: number, item) => {
    if (item.status === 'PAID') {
      return acc + item.amount;
    }

    return acc;
  }, 0);
};

export const TotalPrice = (total: number, vatInclusive?: boolean, vat?: number): number => {
  if (!vatInclusive && vatInclusive !== undefined) {
    return total + ((total * vat!) / 100);
  }

  return total;
};

export const CurrencyFormat = (price: number, currencyIso: string): string => {
  const formatCurrency = new Intl.NumberFormat('nb-NB', {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay: 'narrowSymbol',
  }).format(price).replace(/^(\D+)/, '$1 ');

  return formatCurrency;
};
