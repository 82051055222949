import React from 'react';
// styles
import classes from './StripeConnect.module.scss';
// Components
import NavBar from 'components/NavBar';
import YellowRoundBtn from 'components/Buttons/YellowRoundBtn';
// Images
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as ConnectedIcon } from 'assets/icons/icon-connected.svg';
import stripe from 'assets/images/stripe-logo.png';

const StripeConnect: React.FC = () => (
  <div className={classes.stripe__connect}>
    <NavBar />
    <div className={classes.stripe__connect_container}>
      <div className={classes.stripe__connect_images}>
        <div className={classes.stripe__connect_img}>
          <img src={stripe} alt="stripe" />
        </div>
        <ConnectedIcon />
        <Logo />
      </div>
      <h1 className={classes.stripe__connect_title}>Success</h1>
      <h3 className={classes.stripe__connect_subtitle}>Your stripe account is connected</h3>
      <p className={classes.stripe__connect_text}>
        You can now start accepting card and other forms of payments for your Leja invoices.
      </p>
      <YellowRoundBtn
        type="button"
      >
        Return to the app
      </YellowRoundBtn>
    </div>
  </div>
);

export default StripeConnect;
