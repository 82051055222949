import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useParams } from 'react-router-dom';
// Styles
import classes from './Invoice.module.scss';
// Components
import {
  Container,
} from 'react-bootstrap';
import CustomSpinner from 'components/CustomSpinner';
import DocumentHeader from 'components/DocumentLayout/DocumentHeader';
import DocumentItemService from 'components/DocumentLayout/DocumentItemService';
import InvoicePrice from './InvoicePrice';
import InvoicePayment from './InvoicePayment';
import DocumentFooter from 'components/DocumentLayout/DocumentFooter';
// Actions
import { GetInvoiceData } from 'store/invoice/invoiceActions';

interface IParams {
  id: string
}

const Invoice: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const {
    loading,
    organisation,
    contact,
    details,
    items,
    price,
    termsPayment,
    paid,
  } = useTypeSelector((state) => state.invoice);

  useEffect(() => {
    dispatch(GetInvoiceData(id));
  }, [dispatch, id]);

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <Container className={classes.container}>
      <DocumentHeader
        type="invoice"
        organisation={organisation}
        customer={contact}
        detailsType="invoice"
        detailsNumber={details.invoiceNumber}
        detailsDate={details.invoiceDate}
      />
      <DocumentItemService
        items={items}
        currencyIso={price.currencyIso}
      />
      <InvoicePrice
        price={price}
      />
      <InvoicePayment
        id={id}
        termsPayment={termsPayment}
        isPaid={paid}
        currencyIso={price.currencyIso}
      />
      <DocumentFooter
        name={contact.name}
        text="start creating, sending, and keeping track of your own invoices today with Leja."
        phone={contact.phone}
        documentId={id}
        documentType="invoice"
      />
    </Container>
  );
};

export default Invoice;
