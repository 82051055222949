import {
  INVOICE_LOADING,
  INVOICE_GET_DATA,
  INVOICE_PAY_SUCCESS,
  InvoiceState,
  InvoiceActions,
} from './invoiceTypes';

const initialState: InvoiceState = {
  loading: false,
  organisation: {
    logo: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    country: '',
  },
  contact: {
    name: '',
    fullName: '',
    phone: '',
    address: '',
  },
  details: {
    type: '',
    invoiceNumber: '',
    invoiceDate: '',
  },
  items: [],
  price: {
    currencyIso: 'EUR',
    vatInclusive: true,
    vat: 0,
    importTax: 0,
    subtotal: 0,
    discount: 0,
    total: 0,
  },
  termsPayment: {
    dueDate: '',
    instalements: false,
    notes: '',
    payments: [],
    remainingBalance: 0,
    paymentSchedule: [],
  },
  paid: false,
  paidInfo: null,
};

export const invoiceReducer = (
  state: InvoiceState = initialState,
  action: InvoiceActions,
): InvoiceState => {
  switch (action.type) {
    case INVOICE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case INVOICE_GET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case INVOICE_PAY_SUCCESS:
      return {
        ...state,
        paidInfo: action.payload,
      };
    default:
      return state;
  }
};
