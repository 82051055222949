import {
  ESTIMATE_LOADING,
  ESTIMATE_GET_DATA,
  EstimateState,
  EstimateActions,
} from './estimateTypes';

const initialState: EstimateState = {
  loading: false,
  organisation: {
    logo: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    country: '',
  },
  contact: {
    name: '',
    fullName: '',
    phone: '',
    address: '',
  },
  details: {
    type: '',
    estimateNumber: '',
    estimateDate: '',
  },
  items: [],
  price: {
    currencyIso: 'EUR',
    vatInclusive: true,
    vat: 0,
    importTax: 0,
    subtotal: 0,
    discount: 0,
    total: 0,
  },
  termsPayment: {
    dueDate: '',
    notes: '',
    expiryDate: '',
  },
  status: '',
};

export const estimateReducer = (
  state: EstimateState = initialState,
  action: EstimateActions,
): EstimateState => {
  switch (action.type) {
    case ESTIMATE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ESTIMATE_GET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
