import {
  BILL_LOADING,
  BILL_GET_DATA,
  BillState,
  BillActions,
} from './billTypes';

const initialState: BillState = {
  loading: false,
  organisation: {
    logo: '',
    name: '',
    phone: '',
    email: '',
    address: '',
    country: '',
  },
  contact: {
    name: '',
    fullName: '',
    phone: '',
    address: '',
  },
  details: {
    type: '',
    billNumber: '',
    billDate: '',
  },
  items: [],
  price: {
    currencyIso: 'EUR',
    vatInclusive: true,
    vat: 0,
    importTax: 0,
    subtotal: 0,
    discount: 0,
    total: 0,
  },
  termsPayment: {
    dueDate: '',
    instalements: false,
    notes: '',
    payments: [],
    remainingBalance: 0,
    paymentSchedule: [],
  },
  paid: false,
};

export const billReducer = (
  state: BillState = initialState,
  action: BillActions,
): BillState => {
  switch (action.type) {
    case BILL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case BILL_GET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
