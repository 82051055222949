import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import classes from './NavBar.module.scss';
import logo from 'assets/icons/logo-leja-page.svg';
import { ReactComponent as Globe } from 'assets/icons/globe.svg';

const NavBar: React.FC = () => {
  const [lang, setLang] = useState <string | null>('en');

  const selectHandler = (eventKey: string | null) => {
    setLang(eventKey);
  };

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbar__container}>
        <a
          className={classes.navbar__logolink}
          href="https://www.leja.co/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logo} alt="logo" />
        </a>

        <Dropdown>
          <Dropdown.Toggle
            childBsPrefix={classes.navbar__dropdown_btn}
            bsPrefix={classes.navbar__dropdown}
            id="dropdown-lang"
          >
            <Globe /> {lang?.toUpperCase()}
          </Dropdown.Toggle>
          <Dropdown.Menu bsPrefix={classes.navbar__dropdown_menu} align="right">
            <Dropdown.Item onSelect={selectHandler} eventKey="en">EN</Dropdown.Item>
            <Dropdown.Item onSelect={selectHandler} eventKey="fr">FR</Dropdown.Item>
            <Dropdown.Item onSelect={selectHandler} eventKey="es">ES</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
};

export default NavBar;
