import React from 'react';
import { Button } from 'react-bootstrap';
import classes from './YellowRoundBtn.module.scss';

interface Props {
  onClick?: () => void
  type: 'button' | 'submit'
}

const YellowRoundBtn: React.FC<Props> = ({ children, onClick, type }) => (
  <Button
    onClick={onClick}
    type={type}
    bsPrefix={classes.yellow_round_btn}
  >
    {children}
  </Button>
);

export default YellowRoundBtn;
