import React from 'react';
import dayjs from 'dayjs';
// Styles
import classes from './PaymentSchedule.module.scss';
import { ScheduleItem } from 'types/globalTypes';

interface Props {
  schedule: ScheduleItem[] | undefined
}

const PaymentSchedule: React.FC<Props> = ({ schedule }) => (
  <div className={classes.schedule}>
    <h3 className={classes.schedule__title}>
      Payment schedule
    </h3>
    {schedule?.map((item) => (
      <div key={item.date} className={classes.schedule__item}>
        <div className={item.status === 'PAID' ? classes.schedule__col_paid : classes.schedule__col}>
          <span className={classes.schedule__date}>
            {dayjs(item.date).format('DD MMM YYYY')}
          </span>
          <span className={classes.schedule__amount}>
            {new Intl.NumberFormat('nb-NB', {
              style: 'currency',
              currency: 'EUR',
              currencyDisplay: 'narrowSymbol',
            }).format(item.amount).replace(/^(\D+)/, '$1 ')}
          </span>
        </div>
        {item.status === 'PAID'
          ? (
            <div className={classes.schedule__col}>
              <span className={classes.schedule__status_paid}>
                paid
              </span>
              <span className={classes.schedule__method}>
                Bank Transfer
              </span>
            </div>
          ) : (
            <div className={classes.schedule__col}>
              <button type="button" className={classes.schedule__status_unpaid}>
                pay now
              </button>
            </div>
          )}
      </div>
    ))}
  </div>
);

export default PaymentSchedule;
