import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useParams } from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';
import classes from './Estimate.module.scss';
// Components
import CustomSpinner from 'components/CustomSpinner';
import DocumentHeader from 'components/DocumentLayout/DocumentHeader';
import DocumentItemService from 'components/DocumentLayout/DocumentItemService';
import EstimatePrice from './EstimatePrice';
import EstimateTerms from './EstimateTerms';
import DocumentFooter from 'components/DocumentLayout/DocumentFooter';
// Actions
import { GetEstimateData, SendEstimateStatus } from 'store/estimate/estimateActions';

interface IParams {
  id: string
}

const Estimate: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const {
    loading,
    organisation,
    contact,
    details,
    items,
    price,
    termsPayment,
    status,
  } = useTypeSelector((state) => state.estimate);

  useEffect(() => {
    dispatch(GetEstimateData(id));
  }, [dispatch, id]);

  if (loading) {
    return <CustomSpinner />;
  }

  const sendStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
    const statusString: string | undefined = event.currentTarget.dataset.status;

    if (statusString) {
      dispatch(SendEstimateStatus(statusString, id));
    }
  };

  return (
    <Container className={classes.container}>
      <DocumentHeader
        type="estimate"
        organisation={organisation}
        customer={contact}
        detailsType="estimate"
        detailsNumber={details.estimateNumber}
        detailsDate={details.estimateDate}
      />
      <DocumentItemService
        items={items}
        currencyIso={price.currencyIso}
      />
      <EstimatePrice
        price={price}
      />
      <EstimateTerms
        termsPayment={termsPayment}
        status={status}
        sendStatus={sendStatus}
      />
      <DocumentFooter
        name={contact.name}
        text="start creating, and keeping track of your own estimates today with Leja."
        phone={contact.phone}
        documentId={id}
        documentType="sales-order"
      />
    </Container>
  );
};

export default Estimate;
