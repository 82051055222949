import React from 'react';
import {
  Spinner,
} from 'react-bootstrap';
import classes from './CustomSpinner.module.scss';

const CustomSpinner: React.FC = () => (
  <div className={classes.container}>
    <Spinner className={classes.spinner} animation="border" role="status" variant="primary">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);

export default CustomSpinner;
