import React from 'react';
import SectionHeading from 'components/Typography/SectionHeading';
import classes from './QuoteTax.module.scss';

interface Props {
  tax: boolean
}

const QuoteTax: React.FC<Props> = ({ tax }) => (
  <section className={classes.container}>
    <div className={classes.taxes}>
      <SectionHeading
        text="taxes"
        isUppercase
      />
      <div className={classes.row}>
        <p className={classes.row__title}>
          Include all applicable taxes in prices ?
        </p>
        <span className={classes.row__price}>
          {tax ? 'Yes' : 'No'}
        </span>
      </div>
    </div>
  </section>
);

export default QuoteTax;
