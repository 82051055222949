import React from 'react';
// Styles
import classes from './PaymentSuccess.module.scss';
// Types
import {
  ContactType,
  DetailsType,
  OrganisationType,
} from 'types/globalTypes';
// Icons
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
// Utils
import DocumentFooter from 'components/DocumentLayout/DocumentFooter';

interface Props {
  organisation: OrganisationType
  details: DetailsType
  contact: ContactType
  documentId: string
  entryDocument: string
}

const PaymentSuccess: React.FC<Props> = ({
  organisation,
  details,
  contact,
  documentId,
  entryDocument,
}) => (
  <div className={classes.container}>
    <div className={classes.company_logo}>
      {organisation.logo === '' || organisation.logo === undefined ? (
        <span>
          {organisation.name[0]}
        </span>
      ) : (
        <img src={organisation.logo} alt="logo" />
      )}
    </div>
    <h1 className={classes.company_name}>
      {organisation.name}
    </h1>
    <p className={classes.invoice}>Invoice №: {details.invoiceNumber}</p>
    <SuccessIcon />
    <h2 className={classes.title__success}>Payment successful</h2>

    <div className={classes.divider} />

    <h3 className={classes.title__section}>Amount paid</h3>
    <p className={classes.subtitle__section}>€ 1 550.00</p>
    <p className={classes.text__method}>Card</p>

    <div className={classes.divider} />

    <h3 className={classes.title__section}>Billed to</h3>
    <p className={classes.subtitle__section}>{contact.fullName}</p>
    <p className={classes.text}>{contact.address}</p>
    <p className={classes.text}>{contact.phone}</p>

    <div className={classes.divider} />

    <DocumentFooter
      name={contact.name}
      text="start creating, sending, and keeping track of your own invoices today with Leja."
      phone={contact.phone}
      documentId={documentId}
      documentType={entryDocument}
      isCheckout
    />
  </div>
);

export default PaymentSuccess;
