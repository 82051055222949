import React, { forwardRef } from 'react';
import classes from './QuotePricingItems.module.scss';
// Components
import QuotePricingItem from './QuotePricingItem';
// Types
import { ContactType, ItemType } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

interface Props {
  ref: React.Ref<HTMLDivElement>
  contact: ContactType
  items: ItemType[]
  itemsQuantity: number
  subtotal: number
  currencyIso: string
}

const QuotePricingItems: React.FC<Props> = forwardRef(({
  subtotal,
  contact,
  items,
  itemsQuantity,
  currencyIso,
}, ref) => (
  <div ref={ref} className={classes.pricing__items}>
    <div className={classes.pricing__items_header}>
      <h3 className={classes.pricing__items_title}>
        {itemsQuantity > 1 ? `Quote for ${itemsQuantity} items` : `Quote for ${itemsQuantity} item`}
      </h3>
      <p className={classes.pricing__items_subtitle}>
        from
      </p>
      <p className={classes.pricing__items_name}>
        {contact.fullName}
      </p>
      <p className={classes.pricing__items_address}>
        {`${contact.address} ${contact.phone}`}
      </p>
    </div>
    <div className={classes.pricing__items_container}>
      {items.map((item) => (
        <QuotePricingItem
          key={item.id}
          id={item.id}
          name={item.name}
          description={item.description}
          quantity={item.quantity}
          unitPrice={item.unitPrice}
          options={item.options}
          currencyIso={currencyIso}
        />
      ))}
    </div>
    <div className={classes.pricing__items_totals}>
      <div className={classes.pricing__items_totals_row}>
        <span>
          Subtotal
        </span>
        <span>
          {CurrencyFormat(subtotal, currencyIso)}
        </span>
      </div>
      <div className={classes.pricing__items_totals_row}>
        <span>
          Taxes
        </span>
        <span>
          Included
        </span>
      </div>
      <div className={classes.pricing__items_total}>
        <span>
          Total
        </span>
        <span>
          {CurrencyFormat(subtotal, currencyIso)}
        </span>
      </div>
    </div>
  </div>
));

export default QuotePricingItems;
