import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav: React.FC = () => (
  <nav>
    <ul>
      <li>
        <NavLink to="/invoices/ec3a7108-1531-4a24-9f6e-a98e20f3d21d">
          Paid invoice
        </NavLink>
      </li>
      <li>
        <NavLink to="/invoices/ec3a7108-1531-4a24-9f6e-a98e20f3d21d">
          Unpaid invoice with intalements
        </NavLink>
      </li>
      <li>
        <NavLink to="/invoices/ced880f3-2df0-4e3a-802c-b0ef56e5adca">
          Unpaid invoice without intalements
        </NavLink>
      </li>
      <li>
        <NavLink to="/bills/ckojozai8000101i9f83bg8wz">
          Paid bill
        </NavLink>
      </li>
      <li>
        <NavLink to="/bills/ckojoz6hv000001i9bpmke068">
          Unpaid bill
        </NavLink>
      </li>
      <li>
        <NavLink to="/sales-orders/edcfb536-6201-4b3d-a8eb-9bc5ab412857">
          Estimate
        </NavLink>
      </li>
      <li>
        <NavLink to="/purchase-orders/edcfb536-6201-4b3d-a8eb-9bc5ab412857">
          Quote Request
        </NavLink>
      </li>
      <li>
        <NavLink to="/purchase-orders/details/edcfb536-6201-4b3d-a8eb-9bc5ab412857">
          Quote Pricing Details
        </NavLink>
      </li>
    </ul>
  </nav>
);

export default Nav;
