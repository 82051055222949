import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useParams } from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';
import classes from './Quote.module.scss';
// Components
import SectionHeading from 'components/Typography/SectionHeading';
import CustomSpinner from 'components/CustomSpinner';
import DocumentHeader from 'components/DocumentLayout/DocumentHeader';
import QuoteItem from './QuoteItem';
import QuoteTax from './QuoteTax';
import QuoteTerms from './QuoteTerms';
import DocumentFooter from 'components/DocumentLayout/DocumentFooter';
// Actions
import { GetQuoteData } from 'store/quote/quoteActions';

interface IParams {
  id: string
}

const Quote: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const {
    loading,
    organisation,
    contact,
    details,
    items,
    includeTax,
    termsPayment,
    status,
  } = useTypeSelector((state) => state.quote);

  useEffect(() => {
    dispatch(GetQuoteData(id));
  }, [dispatch, id]);

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <Container className={classes.container}>
      <DocumentHeader
        type="request for quote"
        organisation={organisation}
        customer={contact}
        detailsType="request"
        detailsNumber={details.quoteNumber}
        detailsDate={details.quoteDate}
      />
      <section className={classes.items_container}>
        <SectionHeading
          text="item / service"
          isUppercase
        />
        {items.map((item) => (
          <QuoteItem
            key={item.id}
            title={item.name}
            description={item.description}
            options={item.options}
            quantity={item.quantity}
          />
        ))}
      </section>
      <QuoteTax
        tax={includeTax}
      />
      <QuoteTerms
        id={id}
        termsPayment={termsPayment}
        status={status}
      />
      <DocumentFooter
        name={contact.name}
        text="start creating, and keeping track of your own quote requests today with Leja."
        phone={contact.phone}
        documentId={id}
        documentType="purchase-order"
      />
    </Container>
  );
};

export default Quote;
