import React from 'react';
import { Button } from 'react-bootstrap';
// Styles
import classes from './EstimateTerms.module.scss';
// Components
import SectionHeading from 'components/Typography/SectionHeading';
// Types
import { EstimateTermsPaymentType } from 'store/estimate/estimateTypes';

interface Props {
  termsPayment: EstimateTermsPaymentType
  status: string
  sendStatus: React.MouseEventHandler<HTMLElement>
}

const EstimateTerms: React.FC<Props> = ({ termsPayment, status, sendStatus }) => (
  <section className={classes.container}>
    <SectionHeading
      text="Terms & payment"
      isUppercase
    />
    <div className={classes.payment__row}>
      <p className={classes.payment__row_title}>
        Payment due
      </p>
      <span className={classes.payment__row_subtitle}>
        {termsPayment.dueDate}
      </span>
    </div>
    <div className={classes.notes}>
      <SectionHeading
        text="estimate notes"
        isUppercase
      />
      <p className={classes.notes__text}>
        {termsPayment.notes}
      </p>
    </div>
    <div className={classes.status}>
      <div className={classes.status__row}>
        <SectionHeading
          text="estimate status"
          isUppercase
        />
        <span className={`${classes.status__paid} ${classes.status__unpaid}`}>
          {status}
        </span>
      </div>
    </div>
    <div className={classes.balance}>
      <p className={classes.balance__title}>
        Estimate expiration
      </p>
      <span className={classes.balance__price}>
        {termsPayment.expiryDate}
      </span>
    </div>
    {status === 'PENDING' && (
      <div className={classes.button__block}>
        <Button
          data-status="ACCEPTED"
          onClick={sendStatus}
          variant="secondary"
          bsPrefix={classes.accept_btn}
        >
          Accept
        </Button>
        <Button
          data-status="REJECTED"
          onClick={sendStatus}
          variant="secondary"
          bsPrefix={classes.reject_btn}
        >
          Reject
        </Button>
      </div>
    )}
  </section>
);

export default EstimateTerms;
