import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Invoice from 'pages/Invoice';
import Bill from 'pages/Bill';
import Nav from 'components/Nav';
import Estimate from 'pages/Estimate';
import Quote from 'pages/Quote';
import QuotePricing from 'pages/QuotePricing';
import StripeConnect from 'pages/StripeConnect';
import Checkout from 'pages/Checkout';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`, {
  stripeAccount: 'acct_1IosbmJIh4XwcOm5',
});

const Routes: React.FC = () => (
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <Switch>
        <Route exact path="/" component={Nav} />
        <Route exact path="/invoices/:id" component={Invoice} />
        <Route exact path="/invoices/checkout/:id" component={Checkout} />
        <Route path="/invoices/checkout/:instalment/:id" component={Checkout} />
        <Route path="/bills/:id" component={Bill} />
        <Route path="/sales-orders/:id" component={Estimate} />
        <Route exact path="/purchase-orders/:id" component={Quote} />
        <Route path="/purchase-orders/details/:id" component={QuotePricing} />
        <Route path="/stripe-connect-success" component={StripeConnect} />
        <Redirect to="/" />
      </Switch>
    </Elements>
  </BrowserRouter>
);

export default Routes;
