import {
  OrganisationType,
  ContactType,
  DetailsType,
  ItemType,
  PriceType,
  TermsPaymentType,
} from 'types/globalTypes';

// Action Types
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const INVOICE_GET_DATA = 'INVOICE_GET_DATA';
export const INVOICE_PAY_SUCCESS = 'INVOICE_PAY_SUCCESS';

// Reducer type
export interface InvoiceState {
  loading: boolean
  organisation: OrganisationType
  contact: ContactType
  details: DetailsType
  items: ItemType[]
  price: PriceType
  termsPayment: TermsPaymentType
  paid: boolean
  paidInfo?: any
}

interface InvoiceLoadingAction {
  type: typeof INVOICE_LOADING
  payload: boolean
}

interface GetInvoiceDataAction {
  type: typeof INVOICE_GET_DATA
  payload: InvoiceState
}

interface SetInvoicePaySuccessAction {
  type: typeof INVOICE_PAY_SUCCESS
  payload: any
}

export type InvoiceActions =
InvoiceLoadingAction | GetInvoiceDataAction | SetInvoicePaySuccessAction;
