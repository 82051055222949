import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
// Styles
import classes from './PaymentSchedule.module.scss';
import { ScheduleItem } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

interface Props {
  id: string
  schedule: ScheduleItem[] | undefined
  currencyIso: string
}

const PaymentSchedule: React.FC<Props> = ({ id, schedule, currencyIso }) => (
  <div className={classes.schedule}>
    <h3 className={classes.schedule__title}>
      Payment schedule
    </h3>
    {schedule?.map((item, index) => (
      <div key={item.date} className={classes.schedule__item}>
        <div className={item.status === 'PAID' ? classes.schedule__col_paid : classes.schedule__col}>
          <span className={classes.schedule__date}>
            {dayjs(item.date).format('DD MMM YYYY')}
          </span>
          <span className={classes.schedule__amount}>
            {CurrencyFormat(item.amount, currencyIso)}
          </span>
        </div>
        {item.status === 'PAID'
          ? (
            <div className={classes.schedule__col}>
              <span className={classes.schedule__status_paid}>
                paid
              </span>
              <span className={classes.schedule__method}>
                Bank Transfer
              </span>
            </div>
          ) : (
            <div className={classes.schedule__col}>
              <Link to={`/invoices/checkout/${index}/${id}`}>
                <button type="button" className={classes.schedule__status_unpaid}>
                  pay now
                </button>
              </Link>
            </div>
          )}
      </div>
    ))}
  </div>
);

export default PaymentSchedule;
