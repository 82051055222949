import {
  OrganisationType,
  ContactType,
  ItemType,
} from 'types/globalTypes';

// Action Types
export const QUOTE_LOADING = 'QUOTE_LOADING';
export const QUOTE_GET_DATA = 'QUOTE_GET_DATA';
export const QUOTE_UPDATE_ITEMS = 'QUOTE_UPDATE_ITEMS';
export const QUOTE_SEND_ITEMS = 'QUOTE_SEND_ITEMS';

// Reducer type
export interface QuoteState {
  loading: boolean
  organisation: OrganisationType
  contact: ContactType
  details: QuoteDetailsType
  items: ItemType[]
  itemsQuantity: number
  includeTax: boolean
  termsPayment: QuoteTermsPaymentType
  status: string
  sent?: boolean
}

export type QuoteTermsPaymentType = {
  notes: string
  expiryDate: string
}

export type QuoteDetailsType = {
  type: string,
  quoteNumber: string,
  quoteDate: string,
  currencyIso: string
}

interface QuoteLoadingAction {
  type: typeof QUOTE_LOADING
  payload: boolean
}

interface GetQuoteDataAction {
  type: typeof QUOTE_GET_DATA
  payload: QuoteState
}

interface AddItemPriceAction {
  type: typeof QUOTE_UPDATE_ITEMS
  payload: ItemType[]
}

interface SendItemsAction {
  type: typeof QUOTE_SEND_ITEMS
  payload: boolean
}

export type QuoteActions =
QuoteLoadingAction |
GetQuoteDataAction |
AddItemPriceAction |
SendItemsAction;
