import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
// Styles
import classes from './CardForm.module.scss';
// components
import { Spinner } from 'react-bootstrap';
import {
  useStripe,
  useElements,
  CardElement,
} from '@stripe/react-stripe-js';
import DueButton from 'components/Buttons/DueButton';
// Utils
import { API_URL } from 'utils/constants';
import { InvoiceSetPaySuccessAction } from 'store/invoice/invoiceActions';

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

interface Props {
  price: number
  documentId: string
  currencyIso: string
  contactFullName: string
}

const CardForm: React.FC<Props> = ({
  price,
  documentId,
  currencyIso,
  contactFullName,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      setProcessing(true);

      const { data } = await axios.post(`${API_URL}/shared-invoices/${documentId}/payments`);

      const payload = await stripe.confirmCardPayment(data.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: contactFullName,
          },
        },
      });

      console.log('[PaymentMethod]', payload);

      if (payload.error) {
        // Show error to your customer (e.g., insufficient funds)
        setErrorMsg(payload.error.message);
        console.log(payload.error.message);
      } else {
        // The payment has been processed!
        // eslint-disable-next-line
        if (payload.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          setProcessing(false);
          dispatch(InvoiceSetPaySuccessAction(payload.paymentIntent));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form className={classes.stripe__cardform} onSubmit={handleSubmit}>
        <label className={classes.stripe__cardform_label}>
          <span>Card details</span>
          <CardElement
            className={classes.StripeElement}
            options={CARD_OPTIONS}
            onChange={(event) => {
              console.log('CardElement [change]', event);
              setErrorMsg(event.error?.message);
            }}
          />
          {errorMsg && <p className={classes.stripe__cardform_error}>{errorMsg}</p>}
        </label>
        {processing ? (
          <Spinner
            animation="border"
            role="status"
            variant="primary"
            style={{ margin: '20px auto 24px auto' }}
          />
        ) : (
          <DueButton
            type="submit"
            disabled={!stripe}
            price={price}
            currencyIso={currencyIso}
          />
        )}
      </form>
    </>
  );
};

export default CardForm;
