import React from 'react';
import SectionHeading from 'components/Typography/SectionHeading';
import classes from './BillPrice.module.scss';
import { PriceType } from 'types/globalTypes';

interface Props {
  price: PriceType
}

const BillPrice: React.FC<Props> = ({ price }) => (
  <section className={classes.container}>
    <div className={classes.taxes}>
      <SectionHeading
        text="taxes"
        isUppercase
      />
      <div className={classes.row}>
        <p className={classes.row__title}>
          VAT @ {price.vat}%
        </p>
        <span className={classes.row__price}>
          {new Intl.NumberFormat('nb-NB', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
          }).format((price.subtotal / 100) * price.vat).replace(/^(\D+)/, '$1 ')}
        </span>
      </div>
      <div className={classes.row}>
        <p className={classes.row__title}>
          Import tax @ {price.importTax}%
        </p>
        <span className={classes.row__price}>
          {new Intl.NumberFormat('nb-NB', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
          }).format((price.subtotal / 100) * price.importTax).replace(/^(\D+)/, '$1 ')}
        </span>
      </div>
    </div>
    <div className={classes.subtotal}>
      <div className={classes.subtotal_row}>
        <p className={classes.subtotal_row__title}>
          Subtotal
        </p>
        <span className={classes.subtotal_row__price}>
          {new Intl.NumberFormat('nb-NB', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
          }).format(price.subtotal).replace(/^(\D+)/, '$1 ')}
        </span>
      </div>
      <div className={classes.subtotal_row}>
        <p className={classes.subtotal_row__title}>
          Discount
        </p>
        <span className={classes.subtotal_row__price}>
          {new Intl.NumberFormat('nb-NB', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
          }).format(price.discount || 0).replace(/^(\D+)/, '- $1 ')}
        </span>
      </div>
    </div>
    <div className={classes.total}>
      <p className={classes.total__title}>
        total
      </p>
      <span className={classes.total__price}>
        {new Intl.NumberFormat('nb-NB', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'narrowSymbol',
        }).format(price.total).replace(/^(\D+)/, '$1 ')}
      </span>
    </div>
  </section>
);

export default BillPrice;
