import {
  combineReducers,
  createStore,
  applyMiddleware,
  Action,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk, { ThunkAction } from 'redux-thunk';
// Reducers
import { billReducer } from './bill/billReducer';
import { invoiceReducer } from './invoice/invoiceReducer';
import { estimateReducer } from './estimate/estimateReducer';
import { quoteReducer } from './quote/quoteReducer';

const rootReducer = combineReducers({
  invoice: invoiceReducer,
  bill: billReducer,
  estimate: estimateReducer,
  quote: quoteReducer,
});

const middleware = [reduxThunk];

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

// Store types
export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >
