import React from 'react';
// Styles
import classes from './CheckoutItem.module.scss';
// Types
import { ItemType } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

const CheckoutItem: React.FC<ItemType> = ({
  id,
  name,
  description,
  options,
  quantity,
  unitPrice,
  currencyIso,
}) => (
  <div id={`${id}`} className={classes.item}>
    <div className={classes.item__row}>
      <h3 className={classes.item__row_title}>
        {name}{' '}{description}
      </h3>
      <span className={classes.item__row_price}>
        {CurrencyFormat(unitPrice * quantity, currencyIso)}
      </span>
    </div>
    <div className={classes.item__subrow}>
      <p>
        {options && options.map((option) => (
          <React.Fragment key={option.variant}>
            {option.variant}{' '}
          </React.Fragment>
        ))}
      </p>
      <p>Qty: {quantity}</p>
    </div>
  </div>
);

export default CheckoutItem;
