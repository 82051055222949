import React, { useState } from 'react';
import axios from 'axios';
// Styles
import classes from './DocumentFooter.module.scss';
// Components
import {
  InputGroup,
  FormControl,
  Button,
  Spinner,
  Alert,
} from 'react-bootstrap';
// Utils
import { API_URL } from 'utils/constants';

interface Props {
  name: string;
  text: string;
  phone: string;
  documentId: string
  documentType: string
  isCheckout?: boolean
}

const DocumentFooter: React.FC<Props> = ({
  name = '',
  text = '',
  phone = '',
  documentId,
  documentType,
  isCheckout,
}) => {
  const [phoneNumber, setPhoneNumber] = useState <string>(phone);
  const [loading, setLoading] = useState <boolean>(false);
  const [successMsg, setSuccesMsg] = useState <string | null>(null);
  const [errorMsg, setErrorMsg] = useState <string | null>(null);

  const handlerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handlerSendPhone = async () => {
    setLoading(true);
    setSuccesMsg(null);
    setErrorMsg(null);

    try {
      const { data } = await axios.post(`${API_URL}/links`, JSON.stringify({
        message: 'Hello, download app using the following link',
        number: phoneNumber.split(' ').join(''),
        query: `documentId=${documentId}&documentType=${documentType}`,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (data.status === 'sent') {
        setSuccesMsg('Success! Wait for SMS with link');
      } else {
        setErrorMsg('Check your phone number, and please try again');
      }
    } catch (error) {
      console.log(error);
      setErrorMsg('Something wrong, please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={classes.footer}>
      <p className={classes.footer__title}>
        <span className={classes.footer__title_name}>
          {name},
        </span>
        {text}
      </p>

      {loading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <>
          <InputGroup className={classes.footer__form}>
            <FormControl
              aria-describedby="phone"
              placeholder={phoneNumber}
              className={classes.form_control}
              name="phone"
              type="tel"
              onChange={handlerChange}
              defaultValue={phoneNumber}
            />
            <InputGroup.Append
              className={classes.input_append}
            >
              <Button
                variant="secondary"
                className={classes.custom_btn}
                onClick={handlerSendPhone}
                style={{ backgroundColor: isCheckout ? 'rgba(65, 29, 117, 1)' : 'rgba(74, 74, 74, 1)' }}
              >
                try it now!
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {successMsg && (
            <Alert variant="success" style={{ maxWidth: '284px', margin: '20px auto 0 auto', borderRadius: '50rem' }}>
              {successMsg}
            </Alert>
          )}
          {errorMsg && (
            <Alert variant="danger" style={{ maxWidth: '284px', margin: '20px auto 0 auto', borderRadius: '50rem' }}>
              {errorMsg}
            </Alert>
          )}
        </>
      )}
    </section>
  );
};

export default DocumentFooter;
