import React from 'react';
// Components
import { Button } from 'react-bootstrap';
// Styles
import classes from './DueButton.module.scss';
// Utils
import { CurrencyFormat } from 'utils/functions';

interface Props {
  price: number
  type: 'submit' | 'button'
  form?: string
  disabled: boolean
  currencyIso: string
}

const DueButton: React.FC<Props> = ({
  price,
  type,
  form,
  disabled,
  currencyIso,
}) => (
  <Button
    disabled={disabled}
    type={type}
    form={form}
    bsPrefix={classes.totaldue_btn}
  >
    Pay {CurrencyFormat(price, currencyIso)}
  </Button>
);

export default DueButton;
