import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Styles
import classes from './Checkout.module.scss';
// Components
import CustomSpinner from 'components/CustomSpinner';
import NavBar from 'components/NavBar';
import CheckoutData from './CheckoutData';
import PaymentMethods from './PaymentMethods';
// Actions
import { GetInvoiceData } from 'store/invoice/invoiceActions';
// Icons
import { ReactComponent as PadlockIcon } from 'assets/icons/padlock.svg';
import PaymentSuccess from './PaymentSuccess';

const Checkout: React.FC = () => {
  const { instalment, id } = useParams<{instalment: string, id: string}>();
  const dispatch = useDispatch();
  const {
    loading,
    organisation,
    contact,
    details,
    items,
    price,
    termsPayment,
    paidInfo,
  } = useTypeSelector((state) => state.invoice);

  useEffect(() => {
    dispatch(GetInvoiceData(id));
  }, [dispatch, id]);

  // Scroll to items function
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const executeScroll = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (loading) {
    return <CustomSpinner />;
  }

  if (paidInfo) {
    return (
      <div className={classes.checkout_page}>
        <NavBar />
        <PaymentSuccess
          organisation={organisation}
          details={details}
          contact={contact}
          documentId={id}
          entryDocument={details.type}
        />
      </div>
    );
  }

  return (
    <div className={classes.checkout_page}>
      <NavBar />
      <CheckoutData
        organisation={organisation}
        details={details}
        items={items}
        price={price}
        termsPayment={termsPayment}
        onSmooth={executeScroll}
        instalmentIndex={instalment}
        currencyIso={price.currencyIso}
      />
      <div ref={scrollRef} className={classes.payment}>
        <h3 className={classes.payment__title}>
          How do you want to pay?
        </h3>
        <PadlockIcon className={classes.payment__icon} />
        <p className={classes.payment__text} style={{ marginBottom: '0' }}>
          We use secure transmission and encrypted storage to
        </p>
        <hr />
        <p className={classes.payment__text}>protect your personal information.</p>
        <p className={classes.payment__text}>We use Stripe to process payments</p>
        <PaymentMethods
          documentId={id}
          price={price}
          termsPayment={termsPayment}
          instalmentIndex={instalment}
          contactFullName={contact.fullName}
        />
      </div>
    </div>
  );
};

export default Checkout;
