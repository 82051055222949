import { AppThunk } from 'store';
import axios from 'axios';
import dayjs from 'dayjs';
import { API_URL } from 'utils/constants';
import { ItemType } from 'types/globalTypes';
import {
  ESTIMATE_LOADING,
  EstimateActions,
  EstimateState,
  ESTIMATE_GET_DATA,
} from './estimateTypes';

const EstimateLoadingAction = (payload: boolean): EstimateActions => ({
  type: ESTIMATE_LOADING,
  payload,
});

const EstimateGetDataAction = (payload: EstimateState): EstimateActions => ({
  type: ESTIMATE_GET_DATA,
  payload,
});

export const GetEstimateData = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(EstimateLoadingAction(true));

    const { data } = await axios.get(`${API_URL}/shared-sales-orders/${id}`);
    if (data) {
      const subtotal = data.items.reduce((acc: number, item: ItemType) => (
        acc + (item.unitPrice * item.quantity)
      ), 0);

      const estimateData = {
        loading: false,
        organisation: {
          logo: data.organisation.logo || null,
          name: data.organisation.name,
          phone: data.organisation.phoneNumber,
          email: data.organisation.email,
          address: `${data.organisation.address.address1 || ''} ${data.organisation.address.city || ''} ${data.organisation.address.postalCode || ''}`,
          country: data.organisation.country.name,
        },
        contact: {
          name: data.contact.firstName,
          fullName: `${data.contact.firstName} ${data.contact.lastName}`,
          phone: data.contact.mobileNumber,
          address: `${data.contact.address.address1} ${data.contact.address.city} ${data.contact.address.postalCode}`,
        },
        details: {
          type: data.type,
          estimateNumber: data.reference,
          estimateDate: dayjs(data.createdAt).format('DD MMM YYYY'),
        },
        items: data.items,
        price: {
          currencyIso: data.book.country.currencyISO,
          vatInclusive: data.book.taxDetails[0].type !== 'EXCLUSIVE',
          vat: data.book.taxDetails[0].rate,
          importTax: data.book.taxDetails[1].rate,
          subtotal,
          discount: data.discountAmount,
          total: subtotal - data.discountAmount,
        },
        termsPayment: {
          dueDate: data.terms.firstPaymentDueDate,
          notes: data.notes,
          expiryDate: dayjs(data.expiryDate).format('DD MMM YYYY'),
        },
        status: data.status.name,
      };
      dispatch(EstimateGetDataAction(estimateData));
    }
  } catch (error) {
    console.log(error);
  }
};

export const SendEstimateStatus = (status: string, id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(EstimateLoadingAction(true));
    const { data } = await axios.patch(`${API_URL}/sales-orders/${id}`,
      JSON.stringify({ status: status.toUpperCase() }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    if (data) {
      dispatch(GetEstimateData(id));
    }
  } catch (error) {
    console.log(error);
  }
};
