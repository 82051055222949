import React from 'react';
// Styles
import classes from './CheckoutData.module.scss';
// Components
import CheckoutItem from './CheckoutItem';
import { Button } from 'react-bootstrap';
// Types
import {
  DetailsType,
  ItemType,
  OrganisationType,
  PriceType,
  TermsPaymentType,
} from 'types/globalTypes';
// Utils
import { TotalPrice, CurrencyFormat } from 'utils/functions';

interface Props {
  onSmooth: () => void
  organisation: OrganisationType
  details: DetailsType
  items: ItemType[]
  price: PriceType
  termsPayment: TermsPaymentType
  instalmentIndex: string
  currencyIso: string
}

const CheckoutData: React.FC<Props> = ({
  organisation,
  details,
  items,
  price,
  termsPayment,
  onSmooth,
  instalmentIndex,
  currencyIso,
}) => (
  <div className={classes.container}>
    <div className={classes.company_logo}>
      {organisation.logo === '' || organisation.logo === undefined ? (
        <span>
          {organisation.name[0]}
        </span>
      ) : (
        <img src={organisation.logo} alt="logo" />
      )}
    </div>
    <h1 className={classes.company_name}>
      {organisation.name}
    </h1>
    <p className={classes.invoice}>Invoice №: {details.invoiceNumber}</p>
    {items.map((item) => (
      <React.Fragment key={item.id}>
        <CheckoutItem
          id={item.id}
          name={item.name}
          description={item.description}
          options={item.options}
          quantity={item.quantity}
          unitPrice={item.unitPrice}
          currencyIso={currencyIso}
        />
      </React.Fragment>
    ))}
    <div className={classes.price__wrapper}>
      <div className={classes.price__wrapper_row}>
        <span>Subtotal</span>
        <span className={classes.price__wrapper_price}>
          {CurrencyFormat(price.subtotal, currencyIso)}
        </span>
      </div>
      <div className={classes.price__wrapper_row}>
        <span>Discount</span>
        <span className={classes.price__wrapper_price}>
          {CurrencyFormat(price.discount || 0, currencyIso)}
        </span>
      </div>
      <div className={classes.price__wrapper_row}>
        <span>Tax (VAT @ {price.vat}%)</span>
        <span className={classes.price__wrapper_price}>
          {price.vatInclusive ? 'Included' : CurrencyFormat((price.total * price.vat) / 100, currencyIso)}
        </span>
      </div>
      <div className={termsPayment.instalements
        ? classes.price__wrapper_total_black : classes.price__wrapper_total}
      >
        <span>Total Due</span>
        <span>
          {CurrencyFormat(TotalPrice(price.total, price.vatInclusive, price.vat), currencyIso)}
        </span>
      </div>
    </div>
    {termsPayment.instalements && (
      <div className={classes.price__wrapper}>
        <div className={classes.price__wrapper_row}>
          <span>Payable in instalments?</span>
          <span className={classes.price__wrapper_price}>
            Yes
          </span>
        </div>
        <div className={classes.price__wrapper_instalments}>
          <span>Amout due today</span>
          <span>
            {CurrencyFormat(
              termsPayment.paymentSchedule[Number(instalmentIndex)].amount,
              currencyIso,
            )}
          </span>
        </div>
      </div>
    )}
    <Button onClick={onSmooth} type="button" bsPrefix={classes.paynow_btn}>
      {termsPayment.instalements ? 'Pay Instalment Now' : 'Pay now'}
    </Button>
  </div>
);

export default CheckoutData;
