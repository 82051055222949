import React from 'react';
import classes from './DocumentItemService.module.scss';
import SectionHeading from 'components/Typography/SectionHeading';
import DocumentItem from './DocumentItem';
import { ItemType } from 'types/globalTypes';

interface Props {
  items: ItemType[]
  currencyIso: string
}

const DocumentItemService: React.FC<Props> = ({
  items,
  currencyIso,
}) => (
  <section className={classes.items_container}>
    <SectionHeading
      text="item / service"
      isUppercase
    />
    {items.map((item) => (
      <DocumentItem
        key={item.id}
        id={item.id}
        name={item.name}
        description={item.description}
        options={item.options}
        quantity={item.quantity}
        unitPrice={item.unitPrice}
        currencyIso={currencyIso}
      />
    ))}
  </section>
);

export default DocumentItemService;
