import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import classes from './DocumentHeader.module.scss';
// Images
import { ReactComponent as Logo } from 'assets/icons/logo-leja.svg';
// Components
import SectionHeading from 'components/Typography/SectionHeading';
// Types
import { ContactType, OrganisationType } from 'types/globalTypes';

interface Props {
  type: string
  organisation: OrganisationType
  customer: ContactType
  detailsType: string
  detailsNumber: string
  detailsDate: string
}

const DocumentHeader: React.FC<Props> = ({
  type,
  organisation,
  customer,
  detailsType,
  detailsNumber,
  detailsDate,
}) => (
  <>
    <div className={classes.badge}>
      <Link to="https://www.leja.co/">
        <div className={classes.badge__logo}>
          <span className={classes.badge__text}>
            Powered by
          </span>
          <Logo />
        </div>
      </Link>
    </div>
    <Row className={classes.header}>
      <div className={classes.header__logo}>
        {organisation.logo === '' && organisation.logo === undefined ? (
          <span>
            {organisation.name[0].toUpperCase()}
          </span>
        ) : (
          <img src={organisation.logo} alt="logo" />
        )}
      </div>
      <p className={classes.header__title}>
        {type}
      </p>
    </Row>
    <section className={classes.sender}>
      <ul className={classes.sender__contacts}>
        <li className={classes.sender__contacts_item}>
          {organisation.name}
        </li>
        <li className={classes.sender__contacts_item}>
          {organisation.phone}
        </li>
        <li className={classes.sender__contacts_item}>
          {organisation.email}
        </li>
      </ul>
      <ul className={classes.sender__address}>
        <li>{organisation.address}</li>
        <li>{organisation.country}</li>
      </ul>
    </section>
    <section className={classes.customer}>
      <div>
        <SectionHeading
          text="CUSTOMER / SUPPLIER"
          isUppercase
        />
        <ul className={classes.customer__contacts}>
          <li className={classes.customer__contacts_item}>
            {customer.fullName}
          </li>
          <li className={classes.customer__contacts_item}>
            {customer.phone}
          </li>
          <li className={classes.customer__contacts_item}>
            {customer.address || null}
          </li>
        </ul>
      </div>
      <div>
        <SectionHeading
          text={`${detailsType} details`}
          isUppercase
          isRightAlign
        />
        <ul className={classes.customer__details}>
          <li>{detailsType} №: {detailsNumber}</li>
          <li>{detailsType} Date: {detailsDate}</li>
        </ul>
      </div>
    </section>
  </>
);

export default DocumentHeader;
