import {
  QUOTE_LOADING,
  QUOTE_GET_DATA,
  QUOTE_UPDATE_ITEMS,
  QUOTE_SEND_ITEMS,
  QuoteState,
  QuoteActions,
} from './quoteTypes';

const initialState: QuoteState = {
  loading: false,
  organisation: {
    logo: '',
    name: 'a',
    phone: '',
    email: '',
    address: '',
    country: '',
  },
  contact: {
    name: '',
    fullName: '',
    phone: '',
    address: '',
  },
  details: {
    type: '',
    quoteNumber: '',
    quoteDate: '',
    currencyIso: 'EUR',
  },
  items: [],
  itemsQuantity: 0,
  includeTax: false,
  termsPayment: {
    notes: '',
    expiryDate: '',
  },
  status: '',
  sent: false,
};

export const quoteReducer = (
  state: QuoteState = initialState,
  action: QuoteActions,
): QuoteState => {
  switch (action.type) {
    case QUOTE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case QUOTE_GET_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case QUOTE_UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case QUOTE_SEND_ITEMS:
      return {
        ...state,
        sent: action.payload,
      };
    default:
      return state;
  }
};
