import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// Styles
import classes from './QuoteTerms.module.scss';
// Components
import SectionHeading from 'components/Typography/SectionHeading';
// Types
import { QuoteTermsPaymentType } from 'store/quote/quoteTypes';

interface Props {
  id: string
  termsPayment: QuoteTermsPaymentType
  status: string
}

const QuoteTerms: React.FC<Props> = ({ id, termsPayment, status }) => (
  <section className={classes.container}>
    <div className={classes.notes}>
      <SectionHeading
        text="request notes"
        isUppercase
      />
      <p className={classes.notes__text}>
        {termsPayment.notes}
      </p>
    </div>
    <div className={classes.status}>
      <div className={classes.status__row}>
        <SectionHeading
          text="request status"
          isUppercase
        />
        <span className={`${classes.status__paid} ${classes.status__unpaid}`}>
          {status}
        </span>
      </div>
    </div>
    <div className={classes.expiration}>
      <p className={classes.expiration__title}>
        request expiration
      </p>
      <span className={classes.expiration__price}>
        {termsPayment.expiryDate}
      </span>
    </div>
    {status === 'PENDING' && (
      <div className={classes.button__block}>
        <Link to={`/purchase-orders/details/${id}`}>
          <Button
            variant="secondary"
            bsPrefix={classes.add_btn}
          >
            add pricing details
          </Button>
        </Link>
      </div>
    )}
  </section>
);

export default QuoteTerms;
