import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useParams } from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';
import classes from './Bill.module.scss';
// Components
import CustomSpinner from 'components/CustomSpinner';
import DocumentHeader from 'components/DocumentLayout/DocumentHeader';
import DocumentItemService from 'components/DocumentLayout/DocumentItemService';
import BillPrice from './BillPrice';
import BillPayment from './BillPayment';
import DocumentFooter from 'components/DocumentLayout/DocumentFooter';
// Actions
import { GetBillData } from 'store/bill/billActions';

interface IParams {
  id: string
}

const Bill: React.FC = () => {
  const { id } = useParams<IParams>();
  const dispatch = useDispatch();
  const {
    loading,
    organisation,
    contact,
    details,
    items,
    price,
    termsPayment,
    paid,
  } = useTypeSelector((state) => state.bill);

  useEffect(() => {
    dispatch(GetBillData(id));
  }, [dispatch, id]);

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <Container className={classes.container}>
      <DocumentHeader
        type="bill"
        organisation={organisation}
        customer={contact}
        detailsType="bill"
        detailsNumber={details.billNumber}
        detailsDate={details.billDate}
      />
      <DocumentItemService
        items={items}
        currencyIso={price.currencyIso}
      />
      <BillPrice
        price={price}
      />
      <BillPayment
        termsPayment={termsPayment}
        isPaid={paid}
        currencyIso={price.currencyIso}
      />
      <DocumentFooter
        name={contact.name}
        text="start keeping track of your own bills today with Leja."
        phone={contact.phone}
        documentId={id}
        documentType="bill"
      />
    </Container>
  );
};

export default Bill;
