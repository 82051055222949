import React from 'react';
import classes from './SectionHeading.module.scss';
import classNames from 'classnames';

interface Props {
  text: string;
  isUppercase?: boolean;
  isRightAlign?: boolean;
}

const SectionHeading: React.FC<Props> = ({
  text = '',
  isUppercase = false,
  isRightAlign = false,
}) => {
  const headingClasses = classNames(
    classes.title,
    {
      [classes.title__uppercase]: isUppercase,
      [classes.title__right]: isRightAlign,
    },
  );

  return (
    <p className={headingClasses}>
      {text}
    </p>
  );
};

export default SectionHeading;
