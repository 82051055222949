import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { Container, Button } from 'react-bootstrap';
import classes from './QuotePricing.module.scss';
// Components
import CustomSpinner from 'components/CustomSpinner';
import NavBar from '../../components/NavBar';
import QuotePricingHeader from './QuotePricingHeader';
import QuotePricingItems from './QuotePricingItems';
// Actions
import { GetQuoteData, SendItemsData } from 'store/quote/quoteActions';

const QuotePricing: React.FC = () => {
  const [allowSend, setAllowSend] = useState<boolean>(false);

  const { id } = useParams<{id: string}>();
  const dispatch = useDispatch();
  const {
    loading,
    contact,
    organisation,
    items,
    itemsQuantity,
    details,
    sent,
  } = useTypeSelector((state) => state.quote);

  // Scroll to items function
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const executeScroll = () => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  // Get data
  useEffect(() => {
    dispatch(GetQuoteData(id));
  }, [dispatch, id]);

  // Check if all units have a price
  useLayoutEffect(() => {
    const hasUnitPrice = items.every((item) => item.unitPrice);

    if (hasUnitPrice) {
      setAllowSend(true);
    } else {
      setAllowSend(false);
    }
  }, [items]);

  // Subtotal and total calculation
  const subtotal = items.reduce((totalPrice: number, item) => {
    if (item.unitPrice) {
      return totalPrice + (item.unitPrice * item.quantity);
    }
    return totalPrice;
  }, 0);

  if (loading) {
    return <CustomSpinner />;
  }

  if (sent) {
    return (
      <div className={classes.quote__pricing}>
        <NavBar />
        <Container bsPrefix={classes.quote__pricing_container_sent}>
          <QuotePricingHeader
            organisation={organisation}
            scrollTo={executeScroll}
            sent={sent}
          />
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.quote__pricing}>
      <NavBar />
      <Container bsPrefix={classes.quote__pricing_container}>
        <QuotePricingHeader
          organisation={organisation}
          scrollTo={executeScroll}
        />
        <QuotePricingItems
          ref={scrollRef}
          contact={contact}
          items={items}
          itemsQuantity={itemsQuantity}
          subtotal={subtotal}
          currencyIso={details.currencyIso}
        />
        <Button
          onClick={() => dispatch(SendItemsData(id, items))}
          disabled={!allowSend}
          type="button"
          bsPrefix={classes.quote__pricing_send_btn}
        >
          Send Quote
        </Button>
      </Container>
    </div>
  );
};

export default QuotePricing;
