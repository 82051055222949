import React from 'react';
// Styles
import classes from './DocumentItem.module.scss';
// Types
import { ItemType } from 'types/globalTypes';
// Utils
import { CurrencyFormat } from 'utils/functions';

const DocumentItem: React.FC<ItemType> = ({
  id,
  name = '',
  description = '',
  options,
  quantity,
  unitPrice,
  currencyIso,
}) => (
  <div className={classes.item} id={String(id)}>
    <div className={classes.item__col}>
      <h3 className={classes.item__title}>
        {name}
      </h3>
      <p className={classes.item__descr}>
        {description}
      </p>
      <p className={classes.item__options}>
        {options?.map((option) => (
          <React.Fragment key={option.option}>
            {option.variant}{' '}
          </React.Fragment>
        ))}
      </p>
    </div>
    <div className={classes.item__col}>
      <p className={classes.item__total}>
        {CurrencyFormat(quantity * unitPrice, currencyIso)}
      </p>
      <p className={classes.item__price}>
        {CurrencyFormat(unitPrice, currencyIso)} EA
      </p>
      <p className={classes.item__quantity}>
        QTY: {quantity}
      </p>
    </div>
  </div>
);

export default DocumentItem;
