import {
  OrganisationType,
  ContactType,
  ItemType,
  PriceType,
  TermsPaymentType,
} from 'types/globalTypes';

// Action Types
export const BILL_LOADING = 'BILL_LOADING';
export const BILL_GET_DATA = 'BILL_GET_DATA';

// Reducer type
export interface BillState {
  loading: boolean
  organisation: OrganisationType
  contact: ContactType
  details: BillDetailsType
  items: ItemType[]
  price: PriceType
  termsPayment: TermsPaymentType
  paid: boolean
}

export type BillDetailsType = {
  type: string,
  billNumber: string,
  billDate: string,
}

interface BillLoadingAction {
  type: typeof BILL_LOADING
  payload: boolean
}

interface GetBillDataAction {
  type: typeof BILL_GET_DATA
  payload: BillState
}

export type BillActions = BillLoadingAction | GetBillDataAction;
